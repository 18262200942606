import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from 'react-icons/hi';
import avtar1 from '../../Assets/Images/avtar1.png';
import avtar8 from '../../Assets/Images/avtar8.png';
import avtar9 from '../../Assets/Images/avtar9.png';
import avtar10 from '../../Assets/Images/avtar10.png';
import avtar11 from '../../Assets/Images/avtar11.png';
import avtar12 from '../../Assets/Images/avtar12.png';
import avtar13 from '../../Assets/Images/avtar13.png';
import message2 from '../../Assets/Images/Svg/message2.svg';

export const PatientList = [
    {
        bgColor: "#e59486", img: avtar1, avtar: '12%', head: 'Jerry Howell', cohort: 'Cohort:', span: "Parkinson's Disease", average: ' Weekly Avg', num: 'Number of Steps', number: '2234', icon1: <HiOutlineArrowSmDown />, week: 'Weekly Avg',
        strainght: 'Straight line speed',
        mph: '1.2mph',
        icon2: <HiOutlineArrowSmUp />,
        balance: 'Balance',
        leg: 'Single Leg Stand',
        stand: <><div className='stand-data'>M</div><div className='stand-data'>T</div><div className='stand-data'>W</div><div className='stand-data'> T</div><div className='stand-data'> F</div><div className='stand-data'> S</div><div className='stand-data'> S</div></>,
        strength: 'Strength',
        sit: 'Sit to Stand',
        stadata: <><div className='stand-data'>M</div><div className='stand-data'>T</div><div className='stand-data'>W</div><div className='stand-data'> T</div><div className='stand-data'> F</div><div className='stand-data'> S</div><div className='stand-data'> S</div></>,
        msg: message2,
        button: 'Message',
        detail: 'More Detail',
        avtarColor: "#f8d0c9",
        borderColor: "#e59486",
        color: "#c64028",
        headColor: '#fff'
    },
    {
        bgColor: "#e59486",
        img: avtar10,
        avtar: '19%',
        head: 'David Martin',
        cohort: 'Cohort:',
        span: "Multiple Sclerosis",
        average: ' Weekly Avg',
        num: 'Number of Steps',
        number: '2320',
        icon1: <HiOutlineArrowSmDown />,
        week: 'Weekly Avg',
        strainght: 'Straight line speed',
        mph: '1.3mph',
        icon2: <HiOutlineArrowSmUp />,
        balance: 'Balance',
        leg: 'Single Leg Stand',
        stand: <><div className='stand-blanck'>M</div><div className='stand-data'>T</div><div className='stand-data'>W</div><div className='stand-data'> T</div><div className='stand-data'> F</div><div className='stand-data'> S</div><div className='stand-data'> S</div></>,
        strength: 'Strength',
        sit: 'Ankle Circles',
        stadata: <><div className='stand-blanck'>M</div><div className='stand-data'>T</div><div className='stand-data'>W</div><div className='stand-data'> T</div><div className='stand-data'> F</div><div className='stand-data'> S</div><div className='stand-data'> S</div></>,
        msg: message2,
        button: 'Message',
        detail: 'More Detail',
        avtarColor: "#f8d0c9",
        borderColor: "#e59486",
        color: "#c64028",
        headColor: '#fff'
    },
    {
        bgColor: "#EED1B3",
        img: avtar11,
        avtar: '53%',
        head: 'Kathy Hillingdon',
        cohort: 'Cohort:',
        span: "Stroke",
        average: ' Weekly Avg',
        num: 'Number of Steps',
        number: '3402',
        icon1: <HiOutlineArrowSmUp />,
        week: 'Weekly Avg',
        strainght: 'Straight line speed',
        mph: '1.6mph',
        icon2: <HiOutlineArrowSmUp />,
        balance: 'Balance',
        leg: 'Toe Lifts/Calf Raises',
        stand: <><div className='stand-blanck'>M</div><div className='stand-blanck'>T</div><div className='stand-blanck'>W</div><div className='stand-blanck'>T</div><div className='stand-data'>F</div><div className='stand-data'>S</div><div className='stand-data'>S</div></>,
        strength: null,
        sit: null,
        stadata: null,
        msg: message2,
        button: 'Message',
        detail: 'More Detail',
        avtarColor: "#F6E2D0",
        borderColor: "#CBA681",
        color: "#885C29",
        headColor: '#483A46'
    },
    {
        bgColor: "#98BE98",
        img: avtar8,
        avtar: '83%',
        head: 'Gill Waites',
        cohort: 'Cohort:',
        span: "Parkinson's Disease",
        average: ' Weekly Avg',
        num: 'Number of Steps',
        number: '3212',
        icon1: <HiOutlineArrowSmUp />,
        week: 'Weekly Avg',
        strainght: 'Straight line speed',
        mph: '1.7mph',
        icon2: <HiOutlineArrowSmUp />,
        balance: 'Balance',
        leg: 'Ankle Circles',
        stand: <><div className='stand-blanck'>M</div><div className='stand-blanck'>T</div><div className='stand-blanck'>W</div><div className='stand-blanck'>T</div><div className='stand-data'> F</div><div className='stand-blanck'>S</div><div className='stand-blanck'>S</div></>,
        strength: null,
        sit: null,
        stadata: null,
        msg: message2,
        button: 'Message',
        detail: 'More Detail',
        avtarColor: "#D4E9D4",
        borderColor: "#3D963E",
        color: "#3D963E",
        headColor: '#483A46'
    },
    {
        bgColor: "#98BE98",
        img: avtar12,
        avtar: '83%',
        head: 'Joseph Warner',
        cohort: 'Cohort:',
        span: "Parkinson's Disease",
        average: ' Weekly Avg',
        num: 'Number of Steps',
        number: '3001',
        icon1: <HiOutlineArrowSmUp />,
        week: 'Weekly Avg',
        strainght: 'Straight line speed',
        mph: '1.4mph',
        icon2: <HiOutlineArrowSmUp />,
        balance: 'Balance',
        leg: 'Single Leg Stand',
        stand: <><div className='stand-blanck'>M</div><div className='stand-blanck'>T</div><div className='stand-blanck'>W</div><div className='stand-blanck'>T</div><div className='stand-data'>F</div><div className='stand-blanck'>S</div><div className='stand-blanck'>S</div></>,
        strength: null,
        sit: null,
        stadata: null,
        msg: message2,
        button: 'Message',
        detail: 'More Detail',
        avtarColor: "#D4E9D4",
        borderColor: "#3D963E",
        color: "#3D963E",
        headColor: '#483A46'
    },
    {
        bgColor: "#98BE98",
        img: avtar13,
        avtar: '100%',
        head: 'Antony Madden',
        cohort: 'Cohort:',
        span: "Parkinson's Disease",
        average: ' Weekly Avg',
        num: 'Number of Steps',
        number: '2122',
        icon1: <HiOutlineArrowSmUp />,
        week: 'Weekly Avg',
        strainght: 'Straight line speed',
        mph: '1.2mph',
        icon2: <HiOutlineArrowSmUp />,
        balance: 'Balance',
        leg: 'Toe Lifts/Calf Raises',
        stand: <><div className='stand-blanck' >M</div><div className='stand-blanck' >T</div><div className='stand-blanck' >W</div><div className='stand-blanck' >T</div><div className='stand-blanck' >F</div><div className='stand-blanck' >S</div><div className='stand-blanck' >S</div></>,
        strength: null,
        sit: null,
        stadata: null,
        msg: message2,
        button: 'Message',
        detail: 'More Detail',
        avtarColor: "#D4E9D4",
        borderColor: "#3D963E",
        color: "#3D963E",
        headColor: '#483A46'
    },
    {
        bgColor: "#98BE98",
        img: avtar9,
        avtar: '100%',
        head: 'Steven Walker',
        cohort: 'Cohort:',
        span: "Parkinson's Disease",
        average: ' Weekly Avg',
        num: 'Number of Steps',
        number: '3402',
        icon1: <HiOutlineArrowSmUp />,
        week: 'Weekly Avg',
        strainght: 'Straight line speed',
        mph: '1.6mph',
        icon2: <HiOutlineArrowSmUp />,
        balance: 'Balance',
        leg: 'Single Leg Stand',
        stand: <><div className='stand-blanck' >M</div><div className='stand-blanck' >T</div><div className='stand-blanck' >W</div><div className='stand-blanck' >T</div><div className='stand-blanck' >F</div><div className='stand-blanck' >S</div><div className='stand-blanck' >S</div></>,
        strength: null,
        sit: null,
        stadata: null,
        msg: message2,
        button: 'Message',
        detail: 'More Detail',
        avtarColor: "#D4E9D4",
        borderColor: "#3D963E",
        color: "#3D963E",
        headColor: '#483A46'
    },
]