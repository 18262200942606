import './App.css';
import Routers from './Pages/Route/Routers';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Routers />
  );
}

export default App;
